<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>CMS模块</el-breadcrumb-item>
            <el-breadcrumb-item>文章分类</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加分类</el-button>
        </el-col>
      </el-row>
    </div>

    <el-container>
      <!--分类-->
      <el-aside width="200px">
        <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="name" placeholder="请输入分类名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getTree">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column prop="id" label="ID" width="100"></el-table-column>
            <el-table-column prop="sort" label="序号" width="95">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sort" @change="updatesort(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="iType" label="类型" width="180">
              <template slot-scope="scope">{{['','列表','单页','分类'][scope.row.iType]}}</template>
            </el-table-column>
            <el-table-column prop="name" label="分类名称" width="180"></el-table-column>
            <el-table-column label="缩略图" width="110" align="center">
              <template slot-scope="scope">
                <el-image :src="scope.row.img" alt="缩略图">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="keyWord" label="关键字" width="100"></el-table-column>
            <el-table-column prop="describe" label="描述"></el-table-column>
            <el-table-column prop="addDate" label="添加日期"></el-table-column>
            <el-table-column prop="states" label="状态" width="60">
              <template slot-scope="scope">{{['禁用','启用'][scope.row.states]}}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="250">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="setStates(scope.row.id, 0, scope.row)"
                  v-if="scope.row.states == 1"
                >禁用</el-button>
                <el-button
                  type="success"
                  size="mini"
                  @click="setStates(scope.row.id, 1, scope.row)"
                  v-else
                >启用</el-button>
                <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
                <el-button size="mini" type="danger" @click="onDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  getTreeRequest,
  getSelectRequest,
  setSortRequest,
  setStatesRequest,
  deltypeRequest,
} from '@/api/classify'
export default {
  name: 'ArticleList',
  data() {
    return {
      tableData: [],
      name: '',
      pId: '',
      pageIndex: 1,
      pageTotal: 0,
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  created() {
    this.getSelect()
    this.getTree()
  },
  methods: {
    getTree() {
      getTreeRequest({
        Name: this.name,
        PId: this.pId,
      }).then((res) => {
        console.log(res)
        this.tableData = res.data
      })
    },
    getSelect() {
      getSelectRequest().then((res) => {
        console.log(res)
        this.treedata = res.data
      })
    },
    handleNodeClick(data) {
      console.log(data.value)
      this.pId = data.value
      this.getTree()
    },
    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sort: row.sort,
      }).then((res) => {
        this.getTree()
      })
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states,
      }).then((res) => {
        row.states = states
      })
    },
    toCreate() {
      this.$router.push({
        name: 'Articletypecreate',
      })
    },
    toEdit(id) {
      this.$router.push(`/cmsmange/ArticleType/edit/${id}`)
    },
    onDelete(id) {
      this.$confirm('确认删除该分类吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 调用删除接口
          return deltypeRequest({ id })
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功！`,
          })
          this.getTree()
        })
    },
  },
}
</script>
<style lang="less" scoped>
</style>
